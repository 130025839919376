import $ from 'jquery';

export default {
	init() {
		$('html').addClass('js');

		$('.footer-widgets .be-social-widget').clone().prependTo('.mobile-nav .wrap');


		$(function () {

  // Add open button after mobile menu items with children
  // --------------
  $('#mobile-menu li.menu-item-has-children > a').after($('<div class="mobile-sub-menu-toggle"><i class="fas fa-plus"></i><i class="fas fa-minus"></i></div>'));

  // Open or close sub menus
  $('.mobile-sub-menu-toggle').click(function(e) {
	e.preventDefault();
	$('.mobile-sub-menu-toggle').not($(this)).each(function() {
	  $(this).removeClass('open');
	  return $(this).siblings('ul.sub-menu').slideUp('fast');
	});
	$(this).siblings('ul.sub-menu').slideToggle('fast');
	return $(this).toggleClass('open');
  });


  //-- 

			// Toggle Nav on Click
			$('.nav-toggle').on("click", function (event) {
				// Calling a function in case you want to expand upon this.
				event.preventDefault();
				toggleNav();
			});

			//window.addEventListener('resize', closeNav);
		});

		function toggleNav() {
			if ($('body').hasClass('show-nav')) {
				// Do things on Nav Close
				$('body').removeClass('show-nav');
			} else {
				// Do things on Nav Open
				$('body').addClass('show-nav');
			}
		}

		function closeNav() {
			$('body').removeClass('show-nav');
		}


		if ($('#event-filters').size()) {

			let $filters = $("#event-filters");

			$('<button id="show-search-filters" class="show-search-filters">Show Search Filters </button>').insertBefore("#event-filters");

			$("#show-search-filters").on("click", function () {
				$filters.slideToggle('fast');
			});


			window.addEventListener('resize', function () {
				$filters.hide();
			});
		}

		// Spektrix Donate Web Component
		$(function () {

			// Toggle active class when selecting an amount
			$('button.buy-now').on("click", function (event) {
				$('button.buy-now').each(function () {
					$(this).removeClass('active');
				});
				$(this).addClass('active');
				// Remove the content from the custom amount
				$('.custom-amount').val('');
			});

			// Remove active class from buttons when typing a custom amount

			$('input.custom-amount').click(function () {
				$('button.buy-now').each(function () {
					$(this).removeClass('active');
				});
			});

			// Reset things when clear donation is clicked
			$('.clear-donation').click(function () {
				$('button.buy-now').each(function () {
					$(this).removeClass('active');
				});
				$('.custom-amount').val('');
			});

		});

	},
};
